import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Warehouse, Award, TrendingUp, Users } from 'lucide-react';

const Homepage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-200 flex flex-col justify-center items-center p-8">
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gray-800 p-8 text-white text-center">
          <Warehouse className="h-16 w-16 mx-auto mb-4" />
          <h1 className="text-5xl font-bold mb-2">מערכת לניהול מחסנים</h1>
          <p className="text-xl">פתרון מתקדם לניהול יעיל של מחסנים וצוותי עבודה</p>
        </div>
        
        <div className="p-8">
          <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">יתרונות המערכת</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-start">
              <Award className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">שיפור ביצועים</h3>
                <p className="text-gray-600">מערכת ציונים ובקרה המעודדת מצוינות ומשפרת את ביצועי המחסנאים</p>
              </div>
            </div>
            <div className="flex items-start">
              <TrendingUp className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">הגברת מוטיבציה</h3>
                <p className="text-gray-600">מערכת בונוסים המתגמלת עובדים מצטיינים ומעודדת שיפור מתמיד</p>
              </div>
            </div>
            <div className="flex items-start">
              <Users className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">ניהול צוות יעיל</h3>
                <p className="text-gray-600">כלים לניהול ביקורים, משימות ובקשות עובדים באופן מרוכז ויעיל</p>
              </div>
            </div>
            <div className="flex items-start">
              <Warehouse className="h-8 w-8 text-blue-600 mr-3 flex-shrink-0" />
              <div>
                <h3 className="text-lg font-semibold mb-2">בקרת מחסנים מתקדמת</h3>
                <p className="text-gray-600">מעקב אחר ביצועי המחסנים וזיהוי נקודות לשיפור בזמן אמת</p>
              </div>
            </div>
          </div>
          
          {/* New Section for AI Insights */}
          <div className="mt-8 bg-gray-100 p-6 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-gray-800 mb-4 text-center">הסקת מסקנות בעזרת מערכת בינה מלכותית</h2>
            <p className="text-gray-700 text-center">
              מערכת הבינה המלאכותית שלנו מנתחת נתונים בזמן אמת ומספקת תובנות חשובות לשיפור תהליכי העבודה והביצועים.
              בעזרת אלגוריתמים מתקדמים, ניתן לזהות מגמות ולבצע תחזיות מדויקות שיסייעו בניהול חכם יותר של המשאבים.
            </p>
          </div>
          
          <div className="mt-8 text-center">
            <button
              onClick={() => navigate('/login')}
              className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-3 px-8 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 shadow-lg"
            >
              התחברות למערכת
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 text-center text-gray-600">
        נבנה ע"י ישי אלמולי
      </div>
    </div>
  );
};

export default Homepage;