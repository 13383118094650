import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';

const MonthlyGoalForm = ({ onGoalUpdated, initialData, selectedYear, selectedMonth, selectedBranch }) => {
  const [formData, setFormData] = useState({
    year: selectedYear,
    month: selectedMonth,
    branch: selectedBranch,
    שקיות_קופה: { goal: 0, actual: 0 },
    משטחים: { goal: 0, actual: 0 },
    משטחי_ספקים: { goal: 0, actual: 0 },
    סדר_וניקיון: { goal: 0, actual: 0 },
    מסמכים_לא_חתומים: { goal: 0, actual: 0 },
    חותמות_אדומות: { goal: 0, actual: 0 }
  });
  const [branches, setBranches] = useState([]);
  const [message, setMessage] = useState('');

  const hebrewMonths = [
    'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני',
    'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
  ];

  const hebrewCategories = {
    שקיות_קופה: 'שקיות קופה',
    משטחים: 'משטחים',
    משטחי_ספקים: 'משטחי ספקים',
    סדר_וניקיון: 'סדר וניקיון',
    מסמכים_לא_חתומים: 'מסמכים לא חתומים',
    חותמות_אדומות: 'חותמות אדומות'
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      const currentMonthData = initialData[0];
      setFormData(prevState => ({
        ...prevState,
        year: selectedYear,
        month: selectedMonth,
        branch: selectedBranch,
        שקיות_קופה: currentMonthData.cashierBags || { goal: 0, actual: 0 },
        משטחים: currentMonthData.pallets || { goal: 0, actual: 0 },
        משטחי_ספקים: currentMonthData.supplierPallets || { goal: 0, actual: 0 },
        סדר_וניקיון: currentMonthData.orderAndCleanliness || { goal: 0, actual: 0 },
        מסמכים_לא_חתומים: currentMonthData.unsignedDocuments || { goal: 0, actual: 0 },
        חותמות_אדומות: currentMonthData.redStamps || { goal: 0, actual: 0 }
      }));
    }
  }, [initialData, selectedYear, selectedMonth, selectedBranch]);

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
    } catch (error) {
      console.error('שגיאה בטעינת רשימת הסניפים:', error);
      setMessage('שגיאה בטעינת רשימת הסניפים. אנא נסה שוב.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleGoalDataChange = (category, type, value) => {
    setFormData(prev => ({
      ...prev,
      [category]: { ...prev[category], [type]: Number(value) }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        ...formData,
        cashierBags: formData.שקיות_קופה,
        pallets: formData.משטחים,
        supplierPallets: formData.משטחי_ספקים,
        orderAndCleanliness: formData.סדר_וניקיון,
        unsignedDocuments: formData.מסמכים_לא_חתומים,
        redStamps: formData.חותמות_אדומות
      };
      const response = await axios.post(`${API_URL}/api/monthly-goals`, dataToSend, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setMessage('היעדים החודשיים עודכנו בהצלחה');
      if (onGoalUpdated) onGoalUpdated(response.data);
    } catch (error) {
      console.error('שגיאה בעדכון היעדים החודשיים:', error);
      setMessage('שגיאה בעדכון היעדים החודשיים. אנא נסה שוב.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div>
          <label htmlFor="year" className="block text-sm font-medium text-gray-700 mb-1">שנה</label>
          <input
            type="number"
            id="year"
            name="year"
            value={formData.year}
            onChange={handleInputChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          />
        </div>
        <div>
          <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
          <select
            id="branch"
            name="branch"
            value={formData.branch}
            onChange={handleInputChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            required
          >
            <option value="">בחר סניף</option>
            {branches.map(branch => (
              <option key={branch._id} value={branch.name}>{branch.name}</option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="month" className="block text-sm font-medium text-gray-700 mb-1">חודש</label>
          <select
            id="month"
            name="month"
            value={formData.month}
            onChange={handleInputChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            {hebrewMonths.map((month, index) => (
              <option key={index + 1} value={index + 1}>{month}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="border-t border-gray-200 pt-4">
        <h3 className="text-lg font-semibold mb-4">יעדים ונתונים בפועל - {hebrewMonths[formData.month - 1]}</h3>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(formData).map(([category, values]) => {
            if (['year', 'month', 'branch'].includes(category)) return null;
            return (
              <div key={category} className="bg-gray-50 p-4 rounded-md">
                <h4 className="font-medium text-gray-700 mb-2">{hebrewCategories[category]}</h4>
                <div className="grid grid-cols-2 gap-2">
                  <div>
                    <label htmlFor={`${category}-goal`} className="block text-sm text-gray-600 mb-1">יעד</label>
                    <input
                      type="number"
                      id={`${category}-goal`}
                      value={values.goal}
                      onChange={(e) => handleGoalDataChange(category, 'goal', e.target.value)}
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                  <div>
                    <label htmlFor={`${category}-actual`} className="block text-sm text-gray-600 mb-1">בפועל</label>
                    <input
                      type="number"
                      id={`${category}-actual`}
                      value={values.actual}
                      onChange={(e) => handleGoalDataChange(category, 'actual', e.target.value)}
                      className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-6">
        <button type="submit" className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          עדכן יעדים חודשיים
        </button>
      </div>

      {message && <p className="mt-4 text-sm text-center text-green-600">{message}</p>}
    </form>
  );
};

export default MonthlyGoalForm;