import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../config';

const UserManagement = ({ branches }) => {
  const [newUser, setNewUser] = useState({
    username: '',
    password: '',
    role: 'user',
    branches: []
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({ ...prev, [name]: value }));
  };

  const handleBranchChange = (e) => {
    const selectedBranches = Array.from(e.target.selectedOptions, option => option.value);
    setNewUser(prev => ({ ...prev, branches: selectedBranches }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Sending request to:', `${API_URL}/api/admin/users`);
      console.log('Request payload:', newUser);
      const response = await axios.post(`${API_URL}/api/admin/users`, newUser, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      console.log('Response:', response.data);
      setSuccess('המשתמש נוצר בהצלחה');
      setNewUser({ username: '', password: '', role: 'user', branches: [] });
      setError('');
    } catch (error) {
      console.error('Error creating user:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      setError('שגיאה ביצירת משתמש: ' + (error.response?.data?.message || error.message));
      setSuccess('');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">יצירת משתמש חדש</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">שם משתמש</label>
          <input
            type="text"
            id="username"
            name="username"
            value={newUser.username}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">סיסמה</label>
          <input
            type="password"
            id="password"
            name="password"
            value={newUser.password}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
        </div>
        <div>
          <label htmlFor="role" className="block text-sm font-medium text-gray-700">תפקיד</label>
          <select
            id="role"
            name="role"
            value={newUser.role}
            onChange={handleInputChange}
            required
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          >
            <option value="user">משתמש רגיל</option>
            <option value="manager">מנהל</option>
            <option value="admin">מנהל מערכת</option>
          </select>
        </div>
        <div>
          <label htmlFor="branches" className="block text-sm font-medium text-gray-700">סניפים</label>
          <select
            id="branches"
            name="branches"
            multiple
            value={newUser.branches}
            onChange={handleBranchChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          >
            {branches.map((branch) => (
              <option key={branch._id} value={branch.name}>{branch.name}</option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
        >
          צור משתמש
        </button>
      </form>
    </div>
  );
};

export default UserManagement;