import React from 'react';
import { Plus } from 'lucide-react';
import { useBranchManagement } from './hooks';

const BranchManagement = () => {
  const { newBranch, handleNewBranchChange, addNewBranch, error, success } = useBranchManagement();

  return (
    <div className="bg-gray-50 p-4 rounded-lg">
      <h3 className="text-lg font-semibold mb-4">הוסף סניף</h3>
      {error && <div className="text-red-500 mb-2">{error}</div>}
      {success && <div className="text-green-500 mb-2">{success}</div>}
      <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
        <input
          type="text"
          id="newBranch"
          value={newBranch}
          onChange={handleNewBranchChange}
          className="w-full p-2 border rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="שם הסניף"
        />
        <button 
          onClick={addNewBranch}
          className="w-full sm:w-auto bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          <div className="flex items-center justify-center">
            <Plus className="h-5 w-5 ml-2" />
            הוסף סניף
          </div>
        </button>
      </div>
    </div>
  );
};

export default BranchManagement;