import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Loader, AlertCircle, CheckCircle, Clock, Plus, X, Info } from 'lucide-react';

const FutureVisits = () => {
  const [visits, setVisits] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newVisit, setNewVisit] = useState({ branch: '', visitDate: '', visitPurpose: '', notes: '' });
  const [selectedVisit, setSelectedVisit] = useState(null);

  useEffect(() => {
    fetchVisits();
    fetchBranches();
  }, []);

  const fetchVisits = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/visits`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVisits(response.data);
      setLoading(false);
    } catch (error) {
      setError('שגיאה בטעינת הביקורים');
      setLoading(false);
    }
  };

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/branches`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setBranches(response.data);
    } catch (error) {
      setError('שגיאה בטעינת רשימת הסניפים');
    }
  };

  const handleInputChange = (e) => {
    setNewVisit({ ...newVisit, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/api/visits`, newVisit, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVisits([...visits, response.data]);
      setIsPopupOpen(false);
      setNewVisit({ branch: '', visitDate: '', visitPurpose: '', notes: '' });
    } catch (error) {
      setError('שגיאה ביצירת ביקור חדש');
    }
  };

  const updateVisitStatus = async (visitId, newStatus) => {
    try {
      const response = await axios.put(`${API_URL}/api/visits/${visitId}`, { status: newStatus }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setVisits(visits.map(visit => visit._id === visitId ? response.data : visit));
    } catch (error) {
      setError('שגיאה בעדכון סטטוס הביקור');
    }
  };

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs sm:text-sm font-medium text-gray-600">{title}</p>
          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-2 sm:p-3 rounded-full bg-${color}-100`}>
          <Icon className={`h-4 w-4 sm:h-6 sm:w-6 text-${color}-600`} />
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  const pendingVisits = visits.filter(visit => visit.status === 'מתוכנן').length;
  const completedVisits = visits.filter(visit => visit.status === 'בוצע').length;
  const cancelledVisits = visits.filter(visit => visit.status === 'בוטל').length;

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8" dir="rtl">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8">ביקורים עתידיים</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 mb-6 sm:mb-8">
        <StatCard title="ביקורים מתוכננים" value={pendingVisits} icon={Clock} color="yellow" />
        <StatCard title="ביקורים שבוצעו" value={completedVisits} icon={CheckCircle} color="green" />
        <StatCard title="ביקורים שבוטלו" value={cancelledVisits} icon={X} color="red" />
      </div>

      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg mb-6 sm:mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <h2 className="text-xl font-semibold mb-2 sm:mb-0">רשימת ביקורים</h2>
          <button 
            onClick={() => setIsPopupOpen(true)}
            className="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center text-sm"
          >
            <Plus className="h-4 w-4 mr-1" />
            הוסף ביקור חדש
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך ביקור</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מטרת הביקור</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
                <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">הערות</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {visits.map((visit) => (
                <tr key={visit._id}>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{visit.branch}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{new Date(visit.visitDate).toLocaleDateString('he-IL')}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{visit.visitPurpose}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <select
                      value={visit.status}
                      onChange={(e) => updateVisitStatus(visit._id, e.target.value)}
                      className={`px-2 py-1 rounded-full text-xs font-semibold 
                        ${visit.status === 'בוצע' ? 'bg-green-100 text-green-800' : 
                          visit.status === 'מתוכנן' ? 'bg-yellow-100 text-yellow-800' : 
                          'bg-red-100 text-red-800'}`}
                    >
                      <option value="מתוכנן">מתוכנן</option>
                      <option value="בוצע">בוצע</option>
                      <option value="בוטל">בוטל</option>
                    </select>
                  </td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => setSelectedVisit(visit)}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Info className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Visit Description Popup */}
      {selectedVisit && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{selectedVisit.visitPurpose}</h3>
              <button onClick={() => setSelectedVisit(null)} className="text-gray-500 hover:text-gray-700">
                <X className="h-5 w-5 sm:h-6 sm:w-6" />
              </button>
            </div>
            <p className="text-sm text-gray-600 mb-2">הערות:</p>
            <p className="text-sm">{selectedVisit.notes}</p>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">הוסף ביקור חדש</h3>
              <button onClick={() => setIsPopupOpen(false)} className="text-gray-500 hover:text-gray-700">
                <X className="h-5 w-5 sm:h-6 sm:w-6" />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
                <select
                  id="branch"
                  name="branch"
                  value={newVisit.branch}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  required
                >
                  <option value="">בחר סניף</option>
                  {branches.map(branch => (
                    <option key={branch._id} value={branch.name}>{branch.name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="visitDate" className="block text-sm font-medium text-gray-700 mb-1">תאריך ביקור</label>
                <input
                  type="date"
                  id="visitDate"
                  name="visitDate"
                  value={newVisit.visitDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="visitPurpose" className="block text-sm font-medium text-gray-700 mb-1">מטרת הביקור</label>
                <input
                  type="text"
                  id="visitPurpose"
                  name="visitPurpose"
                  value={newVisit.visitPurpose}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="notes" className="block text-sm font-medium text-gray-700 mb-1">הערות</label>
                <textarea
                  id="notes"
                  name="notes"
                  value={newVisit.notes}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  rows="3"
                />
              </div>
              <button 
                type="submit" 
                className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 text-sm"
              >
                הוסף ביקור
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FutureVisits;