const getApiUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    // Ensure REACT_APP_API_URL is set in production
    if (!process.env.REACT_APP_API_URL) {
      throw new Error('REACT_APP_API_URL is not set in production environment!');
    }
    return process.env.REACT_APP_API_URL;
  }
  return process.env.REACT_APP_API_URL || 'http://localhost:5000';
};

export const API_URL = getApiUrl();

console.log('Current API_URL:', API_URL);