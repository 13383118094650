import React, { useState, useEffect } from 'react';
import { NavLink, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice';
import { LayoutDashboard, FileText, BarChart3, BarChart2, ClipboardList, Calendar, Users, UserPlus, Menu, X, User, LogOut, Brain } from 'lucide-react';
import DashboardContent from './DashboardContent';
import EndOfDayForm from './EndOfDayForm';
import { ReportsList } from './reports';
import AdminPanel from './AdminPanel';
import Tasks from './Tasks';
import FutureVisits from './FutureVisits';
import EmployeeRequests from './EmployeeRequests';
import UserProfile from './UserProfile';
import AIAnalyzerContent from './AIReportAnalysis'; 
import MonthlyGoalsDashboard from './MonthlyGoalsDashboard';

const ModernDashboardLayout = ({ user, onLogout }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('ModernDashboardLayout mounted');
    console.log('User:', user);
    return () => console.log('ModernDashboardLayout unmounted');
  }, [user]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleLogout = () => {
    localStorage.removeItem('user');
    dispatch(logout());
    onLogout();
    navigate('/login');
  };

  const navItems = [
    { path: '/dashboard', icon: LayoutDashboard, text: 'דשבורד' },
    { path: '/dashboard/monthly-goals', icon: BarChart2, text: 'מצב יעדים חודשי' },
    { path: '/dashboard/end-of-day', icon: FileText, text: 'הגשת דו"ח יומי' },
    { path: '/dashboard/reports', icon: BarChart3, text: 'דוחות' },
    { path: '/dashboard/tasks', icon: ClipboardList, text: 'משימות' },
    { path: '/dashboard/future-visits', icon: Calendar, text: 'ביקורים עתידיים' },
    { path: '/dashboard/employee-requests', icon: Users, text: 'בקשות מעובדים' },
    { path: '/dashboard/ai-analyzer', icon: Brain, text: 'מנתח AI' }, 
    ...(user.role === 'admin' ? [{ path: '/dashboard/admin', icon: UserPlus, text: 'ניהול משתמשים' }] : []),
  ];

  if (!user) {
    console.error('User is not defined');
    return <div>Error: User not found</div>;
  }

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100 font-sans" dir="rtl">
      {/* Sidebar */}
      <aside 
        className={`
          bg-gradient-to-b from-blue-600 to-blue-800 text-white w-64 fixed top-0 bottom-0 right-0
          transform transition-all duration-300 ease-in-out
          ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}
          lg:relative lg:translate-x-0
          z-50 shadow-xl
        `}
      >
        <div className="flex items-center justify-between p-4">
          <h2 className="text-xl font-bold">תפריט</h2>
          <button onClick={toggleSidebar} className="lg:hidden text-white hover:text-blue-200 transition-colors">
            <X size={28} />
          </button>
        </div>
        <nav className="mt-8">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              className={({ isActive }) => `
                flex items-center px-6 py-4 transition-all duration-200
                ${isActive ? 'bg-blue-700 border-r-4 border-blue-300' : 'hover:bg-blue-500'}
              `}
              onClick={() => setIsSidebarOpen(false)}
            >
              <item.icon className="h-6 w-6 ml-4" />
              <span className="text-lg">{item.text}</span>
            </NavLink>
          ))}
        </nav>
      </aside>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Bar */}
        <header className="bg-white shadow-md z-40">
          <div className="flex items-center justify-between p-4">
            <button onClick={toggleSidebar} className="text-gray-500 lg:hidden hover:text-blue-600 transition-colors">
              <Menu className="h-8 w-8" />
            </button>
            <h1 className="text-2xl font-bold text-gray-800 hidden lg:block">
              {navItems.find(item => item.path === location.pathname)?.text || 'דשבורד'}
            </h1>
            <div className="flex items-center space-x-2 lg:space-x-4">
              <span className="text-sm text-gray-600 ml-2 hidden lg:inline">שלום, {user.username}</span>
              <button
                onClick={() => navigate('/dashboard/profile')}
                className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 rounded-md text-sm transition-colors duration-200 flex items-center shadow-md"
              >
                <User className="h-5 w-5 lg:ml-2" />
                <span className="lg:inline">פרופיל</span>
              </button>
              <button
                onClick={handleLogout}
                className="bg-red-600 hover:bg-red-700 text-white px-3 py-2 rounded-md text-sm transition-colors duration-200 flex items-center shadow-md"
              >
                <LogOut className="h-5 w-5 lg:ml-2" />
                <span className="lg:inline">התנתקות</span>
              </button>
            </div>
          </div>
        </header>

        {/* Page Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4 lg:p-6">
          <div className="max-w-7xl mx-auto">
            <Routes>
              <Route index element={<DashboardContent />} />
              <Route path="profile" element={<UserProfile user={user} />} />
              <Route path="end-of-day" element={<EndOfDayForm user={user} />} />
              <Route path="reports" element={<ReportsList user={user} />} />
              <Route path="tasks" element={<Tasks user={user} />} />
              <Route path="future-visits" element={<FutureVisits user={user} />} />
              <Route path="employee-requests" element={<EmployeeRequests user={user} />} />
              <Route path="ai-analyzer" element={<AIAnalyzerContent />} />
              <Route path="monthly-goals" element={<MonthlyGoalsDashboard userRole={user.role} />} />
              {user.role === 'admin' && (
                <Route path="admin" element={<AdminPanel user={user} />} />
              )}
            </Routes>
            {/* Developer Info */}
            <div className="text-center text-gray-600 mt-4">
              פותח ע"י ישי אלמולי
            </div>
          </div>
        </main>
      </div>

      {/* Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default ModernDashboardLayout;