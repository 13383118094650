import React, { useState, useEffect } from 'react';
import { ReportsUI } from './ReportsUI';
import { 
  useReportData, 
  useChartRenderers, 
  useReportFilters, 
  useExportToCSV,
  renderPerformanceComparison
} from './ReportsFunctions';

const ReportsList = ({ user }) => {
  const [activeTab, setActiveTab] = useState('overview');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const {
    reports,
    graphData,
    abnormalQuantities,
    trendData,
    isLoading,
    error,
    branches,
    fetchReports,
    processGraphData,
    abnormalItemsDetailed
  } = useReportData(user);

  const {
    startDate,
    endDate,
    selectedBranch,
    setStartDate,
    setEndDate,
    setSelectedBranch,
    filterReports
  } = useReportFilters(reports, processGraphData);

  const { 
    renderBarChart, 
    renderLineChart, 
    renderPieChart, 
    StatCard 
  } = useChartRenderers();

  const exportToCSV = useExportToCSV(reports, startDate, endDate);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    filterReports();
  }, [filterReports]);

  return (
    <ReportsUI
      isLoading={isLoading}
      error={error}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      isMobile={isMobile}
      startDate={startDate}
      endDate={endDate}
      selectedBranch={selectedBranch}
      branches={branches}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      setSelectedBranch={setSelectedBranch}
      exportToCSV={exportToCSV}
      fetchReports={fetchReports}
      reports={reports}
      graphData={graphData}
      abnormalQuantities={abnormalQuantities}
      trendData={trendData}
      renderBarChart={renderBarChart}
      renderLineChart={renderLineChart}
      renderPerformanceComparison={renderPerformanceComparison}
      renderPieChart={renderPieChart}
      StatCard={StatCard}
      abnormalItemsDetailed={abnormalItemsDetailed}
    />
  );
};

export default ReportsList;