import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Homepage from './components/Homepage';
import Login from './components/Login';
import ModernDashboardLayout from './components/ModernDashboardLayout';
import { selectUser, selectLoginTimestamp, logout } from './store/slices/authSlice';

const LOGIN_EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour in milliseconds

function App() {
  const user = useSelector(selectUser);
  const loginTimestamp = useSelector(selectLoginTimestamp);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkLoginStatus = () => {
      if (user && loginTimestamp) {
        const currentTime = Date.now();
        if (currentTime - loginTimestamp > LOGIN_EXPIRATION_TIME) {
          dispatch(logout());
        }
      }
    };

    const intervalId = setInterval(checkLoginStatus, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [user, loginTimestamp, dispatch]);

  const handleLogout = () => {
    console.log('Logging out');
    dispatch(logout());
    localStorage.removeItem('user');
    localStorage.removeItem('loginTimestamp');
  };

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={user ? <Navigate to="/dashboard" replace /> : <Homepage />} 
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/dashboard/*"
          element={
            user ? (
              <ModernDashboardLayout user={user} onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;