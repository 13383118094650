import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import { Loader, AlertCircle, Calendar, ClipboardList, Users, TrendingUp, Info, X } from 'lucide-react';
import { API_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, logout } from '../store/slices/authSlice';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const DescriptionPopup = ({ item, onClose }) => {
  if (!item) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {item.type === 'task' ? item.title :
             item.type === 'visit' ? item.visitPurpose :
             item.type === 'request' ? item.requestType : ''}
          </h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5 sm:h-6 sm:w-6" />
          </button>
        </div>
        <p className="text-sm text-gray-600 mb-2">תיאור:</p>
        <p className="text-sm">
          {item.type === 'task' ? item.description :
           item.type === 'visit' ? item.notes :
           item.type === 'request' ? item.description : ''}
        </p>
      </div>
    </div>
  );
};

const DashboardContent = () => {
  const [dashboardData, setDashboardData] = useState({
    tasks: [],
    visits: [],
    employeeRequests: [],
    endOfDayReports: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [tasksRes, visitsRes, requestsRes, reportsRes] = await Promise.all([
          axios.get(`${API_URL}/api/tasks`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${API_URL}/api/visits`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${API_URL}/api/employee-requests`, {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`${API_URL}/api/reports/user`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        setDashboardData({
          tasks: tasksRes.data,
          visits: visitsRes.data,
          employeeRequests: requestsRes.data,
          endOfDayReports: reportsRes.data,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(logout());
          navigate('/login');
        } else {
          console.error('שגיאה בטעינת נתוני לוח המחוונים:', error);
          setError('טעינת נתוני לוח המחוונים נכשלה. אנא נסה שוב מאוחר יותר.');
        }
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      fetchDashboardData();
    }
  }, [token, dispatch, navigate]);

  const COLORS = ['#4C51BF', '#48BB78', '#ECC94B', '#ED64A6'];

  const StatCard = ({ title, value, icon: Icon, color, onClick }) => (
    <div 
      className="bg-white p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs sm:text-sm font-medium text-gray-600">{title}</p>
          <p className="text-lg sm:text-2xl font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-2 sm:p-3 rounded-full bg-${color}-100`}>
          <Icon className={`h-4 w-4 sm:h-6 sm:w-6 text-${color}-600`} />
        </div>
      </div>
    </div>
  );

  const renderLineChart = (data) => {
    const chartData = {
      labels: data.map(item => item.date),
      datasets: [
        {
          label: 'ציון ממוצע',
          data: data.map(item => item.score),
          borderColor: '#4C51BF',
          backgroundColor: 'rgba(76, 81, 191, 0.1)',
          fill: true,
          tension: 0.4,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'index',
          intersect: false,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: 'white',
          bodyColor: 'white',
          borderColor: 'white',
          borderWidth: 1,
        },
        filler: {
          propagate: true
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          max: 10,
          ticks: {
            stepSize: 2,
          },
        },
      },
    };

    return (
      <div className="h-[200px] sm:h-[300px]">
        <Line data={chartData} options={options} />
      </div>
    );
  };

  const renderPieChart = (data) => {
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          data: data.map(item => item.value),
          backgroundColor: COLORS,
          borderColor: 'white',
          borderWidth: 2,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            boxWidth: 15,
            padding: 15,
            font: {
              size: 12,
            },
          },
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: 'white',
          bodyColor: 'white',
          borderColor: 'white',
          borderWidth: 1,
          callbacks: {
            label: (context) => `${context.label}: ${context.parsed.toFixed(2)}`,
          },
        },
      },
    };

    return (
      <div className="h-[200px] sm:h-[300px]">
        <Pie data={chartData} options={options} />
      </div>
    );
  };

  const openPopup = (item, type) => {
    setSelectedItem({ ...item, type });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader className="animate-spin text-blue-500" size={48} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      </div>
    );
  }

  const pendingTasks = dashboardData.tasks.filter(task => task.status !== 'הושלם').length;
  const upcomingVisits = dashboardData.visits.filter(visit => new Date(visit.visitDate) > new Date()).length;
  const pendingRequests = dashboardData.employeeRequests.filter(request => request.status === 'ממתין לאישור').length;

  const averageScores = dashboardData.endOfDayReports.reduce((acc, report) => {
    acc.storageArrangement += report.storageArrangementScore;
    acc.checkLevel += report.checkLevelScore;
    acc.warehousemanStation += report.warehousemanStationScore;
    acc.returnsCorner += report.returnsCornerScore;
    return acc;
  }, { storageArrangement: 0, checkLevel: 0, warehousemanStation: 0, returnsCorner: 0 });

  const reportsCount = dashboardData.endOfDayReports.length;
  Object.keys(averageScores).forEach(key => {
    averageScores[key] = (averageScores[key] / reportsCount).toFixed(2);
  });

  return (
    <div className="container mx-auto px-4 py-6 sm:py-8" dir="rtl">
      <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8">סקירת לוח מחוונים</h1>
      
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-6 sm:mb-8">
        <StatCard 
          title="משימות ממתינות" 
          value={pendingTasks} 
          icon={ClipboardList} 
          color="yellow" 
          onClick={() => navigate('/dashboard/tasks')}
        />
        <StatCard 
          title="ביקורים קרובים" 
          value={upcomingVisits} 
          icon={Calendar} 
          color="blue" 
          onClick={() => navigate('/dashboard/future-visits')}
        />
        <StatCard 
          title="בקשות ממתינות" 
          value={pendingRequests} 
          icon={Users} 
          color="green" 
          onClick={() => navigate('/dashboard/employee-requests')}
        />
        <StatCard 
          title="ציון מחסן ממוצע" 
          value={averageScores.storageArrangement} 
          icon={TrendingUp} 
          color="indigo" 
          onClick={() => navigate('/dashboard/reports')}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 mb-6 sm:mb-8">
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
          <h2 className="text-lg sm:text-xl font-semibold mb-4">מגמת ציוני מחסן</h2>
          {renderLineChart(dashboardData.endOfDayReports.map(report => ({
            date: new Date(report.date).toLocaleDateString('he-IL'),
            score: (report.storageArrangementScore + report.checkLevelScore + report.warehousemanStationScore + report.returnsCornerScore) / 4
          })))}
        </div>
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
          <h2 className="text-lg sm:text-xl font-semibold mb-4">התפלגות ציונים ממוצעים</h2>
          {renderPieChart([
            { name: 'סידור מחסן', value: parseFloat(averageScores.storageArrangement) },
            { name: 'רמת בדיקה', value: parseFloat(averageScores.checkLevel) },
            { name: 'עמדת מחסנאי', value: parseFloat(averageScores.warehousemanStation) },
            { name: 'פינת חזרות', value: parseFloat(averageScores.returnsCorner) },
          ])}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8">
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
          <h2 className="text-lg sm:text-xl font-semibold mb-4">משימות אחרונות</h2>
          <ul className="divide-y divide-gray-200">
            {dashboardData.tasks
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .slice(0, 5)
              .map((task, index) => (
                <li key={index} className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-xs sm:text-sm font-medium text-gray-900 truncate">{task.title}</p>
                      <p className="text-xs sm:text-sm text-gray-500">{new Date(task.dueDate).toLocaleDateString('he-IL')}</p>
                    </div>
                    <div className="inline-flex items-center text-xs sm:text-sm font-semibold text-gray-900">
                      {task.status}
                    </div>
                    <button
                      onClick={() => openPopup(task, 'task')}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Info className="h-5 w-5" />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg">
          <h2 className="text-lg sm:text-xl font-semibold mb-4">ביקורים קרובים</h2>
          <ul className="divide-y divide-gray-200">
            {dashboardData.visits
              .filter(visit => new Date(visit.visitDate) > new Date())
              .sort((a, b) => new Date(a.visitDate) - new Date(b.visitDate))
              .slice(0, 5)
              .map((visit, index) => (
                <li key={index} className="py-3 sm:py-4">
                  <div className="flex items-center space-x-4">
                    <div className="flex-1 min-w-0">
                      <p className="text-xs sm:text-sm font-medium text-gray-900 truncate">{visit.branch}</p>
                      <p className="text-xs sm:text-sm text-gray-500">{new Date(visit.visitDate).toLocaleDateString('he-IL')}</p>
                    </div>
                    <div className="inline-flex items-center text-xs sm:text-sm font-semibold text-gray-900">
                      {visit.visitPurpose}
                    </div>
                    <button
                      onClick={() => openPopup(visit, 'visit')}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Info className="h-5 w-5" />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <div className="mt-6 sm:mt-8 bg-white p-4 sm:p-6 rounded-lg shadow-lg overflow-x-auto">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">בקשות עובדים אחרונות</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">עובד</th>
              <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סוג בקשה</th>
              <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סטטוס</th>
              <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך</th>
              <th className="px-3 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תיאור</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {dashboardData.employeeRequests
              .sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate))
              .slice(0, 5)
              .map((request, index) => (
                <tr key={index}>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{request.employeeName}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{request.requestType}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{request.status}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap text-xs sm:text-sm">{new Date(request.requestDate).toLocaleDateString('he-IL')}</td>
                  <td className="px-3 sm:px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => openPopup(request, 'request')}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Info className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {selectedItem && (
        <DescriptionPopup
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
};

export default DashboardContent;