import React from 'react';
import { Loader, AlertCircle } from 'lucide-react';

export const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <Loader className="animate-spin h-10 w-10 text-indigo-600" />
  </div>
);

export const AccessDenied = () => (
  <div className="container mx-auto p-6">
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
      <p className="font-bold">אין הרשאה</p>
      <p>אין לך הרשאות לצפות בדף זה.</p>
    </div>
  </div>
);

export const PanelHeader = () => (
  <div className="bg-gradient-to-r from-indigo-700 to-indigo-800 p-4">
    <h2 className="text-2xl font-bold text-white">לוח ניהול</h2>
  </div>
);

export const ErrorMessage = ({ error }) => {
  if (!error) return null;
  return (
    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
      <div className="flex">
        <AlertCircle className="h-5 w-5 ml-2" />
        <p>{error}</p>
      </div>
    </div>
  );
};

export const SuccessMessage = ({ success }) => {
  if (!success) return null;
  return (
    <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
      <p>{success}</p>
    </div>
  );
};