import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import { Save, AlertCircle, CheckCircle, Plus, Trash2 } from 'lucide-react';

const EndOfDayForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    date: '',
    branch: '',
    storageArrangementScore: '',
    checkLevelScore: '',
    warehousemanStationScore: '',
    returnsCornerScore: '',
    workWithProcedures: '',
    abnormalGoodsStatus: '',
    abnormalGoodsList: [],
    damagedGoodsStatus: '',
    damagedGoodsList: [],
    excessManpower: '',
    lackOfManpower: '',
    trainingNeeds: '',
    warehousemanWorkDetails: '',
    improvementSuggestions: '',
    equipmentStatus: '',
    equipmentGaps: '',
  });
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchUserDataAndBranches = async () => {
      try {
        const [userResponse, branchesResponse] = await Promise.all([
          axios.get(`${API_URL}/api/users/profile`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          }),
          axios.get(`${API_URL}/api/branches`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          })
        ]);

        setFormData(prevState => ({
          ...prevState,
          fullName: userResponse.data.fullName || ''
        }));

        setBranches(branchesResponse.data);
      } catch (error) {
        console.error('Error fetching user data or branches:', error);
        setError('שגיאה בטעינת נתוני משתמש או סניפים');
      }
    };

    fetchUserDataAndBranches();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleGoodsListChange = (type, index, field, value) => {
    setFormData(prevState => {
      const newList = [...prevState[`${type}GoodsList`]];
      newList[index] = { ...newList[index], [field]: value };
      return { ...prevState, [`${type}GoodsList`]: newList };
    });
  };

  const addGoodsItem = (type) => {
    setFormData(prevState => ({
      ...prevState,
      [`${type}GoodsList`]: [...prevState[`${type}GoodsList`], { product: '', barcode: '', quantity: '' }]
    }));
  };

  const removeGoodsItem = (type, index) => {
    setFormData(prevState => ({
      ...prevState,
      [`${type}GoodsList`]: prevState[`${type}GoodsList`].filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/api/reports`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('Report submitted:', response.data);
      setSuccess(true);
      // Reset form fields except fullName
      setFormData(prevState => ({
        ...prevState,
        date: '',
        branch: '',
        storageArrangementScore: '',
        checkLevelScore: '',
        warehousemanStationScore: '',
        returnsCornerScore: '',
        workWithProcedures: '',
        abnormalGoodsStatus: '',
        abnormalGoodsList: [],
        damagedGoodsStatus: '',
        damagedGoodsList: [],
        excessManpower: '',
        lackOfManpower: '',
        trainingNeeds: '',
        warehousemanWorkDetails: '',
        improvementSuggestions: '',
        equipmentStatus: '',
        equipmentGaps: '',
      }));
    } catch (error) {
      console.error('Error submitting report:', error);
      setError(`שגיאה בשליחת הטופס: ${error.response?.data?.message || error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderScoreSelect = (name, label) => (
    <div className="mb-4">
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <select
        id={name}
        name={name}
        value={formData[name]}
        onChange={handleInputChange}
        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
        required
      >
        <option value="">בחר ציון</option>
        {[1,2,3,4,5,6,7,8,9,10].map(score => (
          <option key={score} value={score}>{score}</option>
        ))}
      </select>
    </div>
  );

  const renderRadioGroup = (name, label, options = ['כן', 'לא']) => (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-2">{label}</label>
      <div className="flex flex-wrap gap-4">
        {options.map((option) => (
          <label key={option} className="inline-flex items-center">
            <input
              type="radio"
              name={name}
              value={option}
              checked={formData[name] === option}
              onChange={handleInputChange}
              className="form-radio text-blue-600 h-5 w-5"
              required
            />
            <span className="mr-2 text-sm text-gray-700">{option}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const renderGoodsList = (type) => (
    <div className="mb-4">
      <h4 className="text-lg font-semibold mb-2">
        {type === 'abnormal' ? 'פירוט סחורה תקינה חריגה' : 'פירוט סחורה פגומה חריגה'}
      </h4>
      {formData[`${type}GoodsList`].map((item, index) => (
        <div key={index} className="flex flex-wrap -mx-2 mb-2">
          <div className="w-full md:w-1/3 px-2 mb-2">
            <input
              type="text"
              placeholder="מוצר"
              value={item.product}
              onChange={(e) => handleGoodsListChange(type, index, 'product', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full md:w-1/3 px-2 mb-2">
            <input
              type="text"
              placeholder="ברקוד"
              value={item.barcode}
              onChange={(e) => handleGoodsListChange(type, index, 'barcode', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full md:w-1/4 px-2 mb-2">
            <input
              type="number"
              placeholder="כמות"
              value={item.quantity}
              onChange={(e) => handleGoodsListChange(type, index, 'quantity', e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="w-full md:w-auto px-2 mb-2 flex items-center">
            <button
              type="button"
              onClick={() => removeGoodsItem(type, index)}
              className="p-2 bg-red-100 text-red-600 rounded-md hover:bg-red-200 transition duration-200"
            >
              <Trash2 size={20} />
            </button>
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={() => addGoodsItem(type)}
        className="mt-2 px-4 py-2 bg-blue-100 text-blue-600 rounded-md hover:bg-blue-200 transition duration-200 flex items-center"
      >
        <Plus size={20} className="ml-2" />
        הוסף פריט
      </button>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8" dir="rtl">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">הגשת דו"ח יומי</h1>
      
      {error && (
        <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <div className="flex">
            <AlertCircle className="h-5 w-5 ml-2" />
            <p>{error}</p>
          </div>
        </div>
      )}
      
      {success && (
        <div className="bg-green-100 border-r-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
          <div className="flex">
            <CheckCircle className="h-5 w-5 ml-2" />
            <p>הדוח נשלח בהצלחה!</p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">פרטים כלליים</h2>
            <div className="mb-4">
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">שם מלא</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                className="w-full p-2 border border-gray-300 rounded-md bg-gray-100"
                readOnly
              />
            </div>
            <div className="mb-4">
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">תאריך</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
              <select
                id="branch"
                name="branch"
                value={formData.branch}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">בחר סניף</option>
                {branches.map(branch => (
                  <option key={branch._id} value={branch.name}>{branch.name}</option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">ציוני מחסן</h2>
            {renderScoreSelect('storageArrangementScore', 'ציון סידור המחסן')}
            {renderScoreSelect('checkLevelScore', 'ציון רמת בדיקה')}
            {renderScoreSelect('warehousemanStationScore', 'ציון עמדת מחסנאי')}
            {renderScoreSelect('returnsCornerScore', 'ציון פינת חזרות')}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">מצב סחורה</h2>
            {renderRadioGroup('workWithProcedures', 'עבודה עם נהלים')}
            {renderRadioGroup('abnormalGoodsStatus', 'האם יש סחורה תקינה חריגה?')}
            {formData.abnormalGoodsStatus === 'כן' && renderGoodsList('abnormal')}
            {renderRadioGroup('damagedGoodsStatus', 'האם יש סחורה פגומה חריגה?')}
            {formData.damagedGoodsStatus === 'כן' && renderGoodsList('damaged')}
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">כוח אדם וציוד</h2>
            {renderRadioGroup('excessManpower', 'עודף בכח אדם')}
            {renderRadioGroup('lackOfManpower', 'חוסר בכח אדם')}
            {renderRadioGroup('equipmentStatus', 'תקינות ציוד המחסן', ['תקין', 'לא תקין'])}
            {formData.equipmentStatus === 'לא תקין' && (
              <div className="mb-4">
                <label htmlFor="equipmentGaps" className="block text-sm font-medium text-gray-700 mb-1">פירוט פערים בציוד</label>
                <textarea
                  id="equipmentGaps"
                  name="equipmentGaps"
                  value={formData.equipmentGaps}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                ></textarea>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-xl font-semibold mb-4">פרטים נוספים</h2>
          <div className="mb-4">
            <label htmlFor="trainingNeeds" className="block text-sm font-medium text-gray-700 mb-1">צרכי הדרכה</label>
            <textarea
              id="trainingNeeds"
              name="trainingNeeds"
              value={formData.trainingNeeds}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="warehousemanWorkDetails" className="block text-sm font-medium text-gray-700 mb-1">פירוט עבודת מחסנאי</label>
            <textarea
              id="warehousemanWorkDetails"
              name="warehousemanWorkDetails"
              value={formData.warehousemanWorkDetails}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
          <div className="mb-4">
            <label htmlFor="improvementSuggestions" className="block text-sm font-medium text-gray-700 mb-1">הצעות לשיפור</label>
            <textarea
              id="improvementSuggestions"
              name="improvementSuggestions"
              value={formData.improvementSuggestions}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              rows="3"
            ></textarea>
          </div>
        </div>

        <div className="flex justify-center">
          <button 
            type="submit" 
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            disabled={isLoading}
          >
            <Save className="inline-block ml-2 h-5 w-5" />
            {isLoading ? 'שולח...' : 'שלח דו"ח'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EndOfDayForm;