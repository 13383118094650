import React, { useState } from 'react';
import { Trash2, Edit2, Info } from 'lucide-react';
import EditUser from './EditUser';

const UserList = ({ users, onDeleteUser, branches, onUpdateUser }) => {
  const [editingUser, setEditingUser] = useState(null);
  const [showDebug, setShowDebug] = useState(false);

  const toggleDebug = () => setShowDebug(!showDebug);

  return (
    <div className="bg-gray-50 p-4 rounded-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">רשימת משתמשים</h3>
        <button 
          onClick={toggleDebug}
          className="bg-gray-300 text-gray-700 p-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          <Info className="h-5 w-5" />
        </button>
      </div>
      <ul className="space-y-2">
        {users && users.map((user) => (
          <li key={user._id} className="bg-white p-3 rounded-md shadow flex flex-col">
            <div className="flex justify-between items-start">
              <div className="flex flex-col">
                <span className="font-medium">{user.username}</span>
                <span className="text-sm text-gray-500">
                  תפקיד: {user.role === 'admin' ? 'מנהל מערכת' : user.role === 'manager' ? 'מנהל' : 'משתמש רגיל'}
                </span>
                <span className="text-sm text-gray-500">
                  סניפים: {Array.isArray(user.branches) && user.branches.length > 0 ? 
                    user.branches.join(', ') : 'אין סניפים משויכים'}
                </span>
              </div>
              <div>
                <button 
                  onClick={() => setEditingUser(user)}
                  className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mr-2"
                >
                  <Edit2 className="h-5 w-5" />
                </button>
                <button 
                  onClick={() => onDeleteUser(user._id)}
                  className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
            {showDebug && (
              <div className="mt-2 text-xs text-gray-500 bg-gray-100 p-2 rounded">
                <pre>{JSON.stringify(user, null, 2)}</pre>
              </div>
            )}
          </li>
        ))}
      </ul>
      {editingUser && (
        <EditUser 
          user={editingUser} 
          branches={branches} 
          onClose={() => setEditingUser(null)} 
          onUpdate={(updatedUser) => {
            onUpdateUser(updatedUser);
            setEditingUser(null);
          }}
        />
      )}
    </div>
  );
};

export default UserList;