import { useState } from 'react';
import { makeApiCall } from './useAdminPanel';

export const useBranchManagement = () => {
  const [newBranch, setNewBranch] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleNewBranchChange = (e) => {
    setNewBranch(e.target.value);
  };

  const addNewBranch = async () => {
    if (!newBranch) return;

    try {
      await makeApiCall('branches', 'POST', { name: newBranch }, false);  // Use non-admin route
      setNewBranch('');
      setSuccess('הסניף נוסף בהצלחה');
    } catch (error) {
      setError('שגיאה בהוספת סניף חדש. אנא נסה שוב.');
    }
  };

  return {
    newBranch,
    handleNewBranchChange,
    addNewBranch,
    error,
    success
  };
};