import React, { useState, useEffect } from 'react';
import { X, Plus } from 'lucide-react';

const EditUser = ({ user = {}, branches = [], onClose, onUpdate }) => {
  const [editedUser, setEditedUser] = useState({
    ...user,
    branches: user.branches || []
  });
  const [selectedBranch, setSelectedBranch] = useState('');

  useEffect(() => {
    setEditedUser({
      ...user,
      branches: user.branches || []
    });
    console.log('Initial user data:', user);
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser(prev => {
      const updated = { ...prev, [name]: value };
      console.log(`Input changed: ${name} = ${value}`, updated);
      return updated;
    });
  };

  const handleBranchSelection = (e) => {
    setSelectedBranch(e.target.value);
    console.log('Selected branch:', e.target.value);
  };

  const addBranchToUser = () => {
    if (selectedBranch && !editedUser.branches.includes(selectedBranch)) {
      setEditedUser(prev => {
        const updated = { ...prev, branches: [...prev.branches, selectedBranch] };
        console.log('Branch added to user:', updated);
        return updated;
      });
      setSelectedBranch('');
    }
  };

  const removeBranchFromUser = (branchName) => {
    setEditedUser(prev => {
      const updated = { ...prev, branches: prev.branches.filter(b => b !== branchName) };
      console.log('Branch removed from user:', updated);
      return updated;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting edited user:', editedUser);
    onUpdate(editedUser);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">עריכת משתמש</h3>
          <form onSubmit={handleSubmit} className="mt-2 text-left">
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">שם משתמש</label>
              <input
                type="text"
                id="username"
                name="username"
                value={editedUser.username || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="role" className="block text-sm font-medium text-gray-700">תפקיד</label>
              <select
                id="role"
                name="role"
                value={editedUser.role || 'user'}
                onChange={handleInputChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              >
                <option value="user">משתמש רגיל</option>
                <option value="manager">מנהל</option>
                <option value="admin">מנהל מערכת</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="branch" className="block text-sm font-medium text-gray-700">הוסף סניף</label>
              <div className="flex mt-1">
                <select
                  id="branch"
                  value={selectedBranch}
                  onChange={handleBranchSelection}
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                  <option value="">בחר סניף</option>
                  {branches.map((branch) => (
                    <option key={branch._id} value={branch.name}>{branch.name}</option>
                  ))}
                </select>
                <button
                  type="button"
                  onClick={addBranchToUser}
                  className="ml-2 bg-green-500 text-white p-2 rounded-md hover:bg-green-600"
                >
                  <Plus className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">סניפים משויכים</label>
              <ul className="mt-1 space-y-2">
                {editedUser.branches.map((branchName) => (
                  <li key={branchName} className="flex justify-between items-center bg-gray-100 p-2 rounded-md">
                    <span>{branchName}</span>
                    <button 
                      type="button"
                      onClick={() => removeBranchFromUser(branchName)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400"
              >
                ביטול
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                שמור שינויים
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;