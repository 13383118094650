import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, RadialLinearScale, Filler, Tooltip, Legend } from 'chart.js';
import { Line, Bar, Pie, Radar } from 'react-chartjs-2';
import { DownloadCloud, CalendarIcon, RefreshCw, AlertCircle, TrendingUp, AlertTriangle, FileText, BarChart2, ActivityIcon } from 'lucide-react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  RadialLinearScale,
  Filler,
  Tooltip,
  Legend
);

export const ReportsUI = ({
  isLoading,
  error,
  startDate,
  endDate,
  selectedBranch,
  branches,
  setStartDate,
  setEndDate,
  setSelectedBranch,
  exportToCSV,
  fetchReports,
  reports,
  graphData,
  abnormalQuantities,
  trendData,
  isMobile,
  abnormalItemsDetailed
}) => {
  const [activeTab, setActiveTab] = React.useState('overview');

  const StatCard = ({ title, value, icon: Icon, color }) => (
    <div className={`bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300`}>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-xs font-medium text-gray-600">{title}</p>
          <p className="text-lg font-semibold text-gray-900">{value}</p>
        </div>
        <div className={`p-2 rounded-full bg-${color}-100`}>
          <Icon className={`h-4 w-4 text-${color}-600`} />
        </div>
      </div>
    </div>
  );

  const renderLineChart = (data, title) => {
    const chartData = {
      labels: data.map(item => item.date),
      datasets: [
        {
          label: 'סידור מחסן',
          data: data.map(item => item.storageArrangement),
          borderColor: '#0088FE',
          backgroundColor: 'rgba(0, 136, 254, 0.1)',
          fill: true,
        },
        {
          label: 'רמת בדיקה',
          data: data.map(item => item.checkLevel),
          borderColor: '#00C49F',
          backgroundColor: 'rgba(0, 196, 159, 0.1)',
          fill: true,
        },
        {
          label: 'עמדת מחסנאי',
          data: data.map(item => item.warehousemanStation),
          borderColor: '#FFBB28',
          backgroundColor: 'rgba(255, 187, 40, 0.1)',
          fill: true,
        },
        {
          label: 'פינת חזרות',
          data: data.map(item => item.returnsCorner),
          borderColor: '#FF8042',
          backgroundColor: 'rgba(255, 128, 66, 0.1)',
          fill: true,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            font: {
              size: 12,
            },
          },
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 10,
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: 10,
            },
          },
        },
      },
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <div className="h-64 md:h-80">
          <Line data={chartData} options={options} />
        </div>
      </div>
    );
  };

  const renderBarChart = (data, dataKey, color, title) => {
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          label: title,
          data: data.map(item => item[dataKey]),
          backgroundColor: color,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 10,
          ticks: {
            font: {
              size: 10,
            },
          },
        },
        x: {
          ticks: {
            font: {
              size: 10,
            },
          },
        },
      },
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <div className="h-64 md:h-80">
          <Bar data={chartData} options={options} />
        </div>
      </div>
    );
  };

  const renderPieChart = (data, title) => {
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          data: data.map(item => item.value),
          backgroundColor: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'],
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            font: {
              size: 12,
            },
          },
        },
        title: {
          display: false,
        },
      },
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-semibold mb-4">{title}</h2>
        <div className="h-64 md:h-80">
          <Pie data={chartData} options={options} />
        </div>
      </div>
    );
  };

  const renderPerformanceComparison = (data) => {
    const chartData = {
      labels: data.map(item => item.name),
      datasets: [
        {
          label: 'ציונים',
          data: data.map(item => item.value),
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgb(75, 192, 192)',
          pointBackgroundColor: 'rgb(75, 192, 192)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgb(75, 192, 192)',
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
      },
      scales: {
        r: {
          angleLines: {
            display: false,
          },
          suggestedMin: 0,
          suggestedMax: 10,
          ticks: {
            font: {
              size: 10,
            },
          },
        },
      },
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <div className="h-64 md:h-80">
          <Radar data={chartData} options={options} />
        </div>
      </div>
    );
  };

  const renderOverview = () => {
    const totalReports = reports.length;
    const avgCombinedScore = graphData.combinedScore.length > 0 
        ? graphData.combinedScore.reduce((sum, item) => sum + Number(item.combinedScore), 0) / graphData.combinedScore.length 
        : 0;
    const totalAbnormalGood = abnormalQuantities.length > 0 
        ? abnormalQuantities.reduce((sum, item) => sum + (Number(item.abnormalGood) || 0), 0)
        : 0;
    const totalAbnormalDamaged = abnormalQuantities.length > 0 
        ? abnormalQuantities.reduce((sum, item) => sum + (Number(item.abnormalDamaged) || 0), 0)
        : 0;

    return (
      <div className="space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <StatCard title="סה״כ דוחות" value={totalReports} icon={FileText} color="blue" />
          <StatCard title="ציון ממוצע כולל" value={avgCombinedScore.toFixed(2)} icon={TrendingUp} color="green" />
          <StatCard title="סה״כ פריטים חריגים (תקינים)" value={totalAbnormalGood} icon={AlertTriangle} color="yellow" />
          <StatCard title="סה״כ פריטים חריגים (פגומים)" value={totalAbnormalDamaged} icon={AlertTriangle} color="red" />
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-bold text-gray-800 mb-4">מגמת ציונים לאורך זמן</h3>
          {renderLineChart(trendData, "")}
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-bold text-gray-800 mb-4">השוואת ביצועים בין קטגוריות</h3>
          {renderPerformanceComparison([
            { name: 'סידור מחסן', value: graphData.storageArrangement[0]?.storageArrangement || 0 },
            { name: 'רמת בדיקה', value: graphData.checkLevel[0]?.checkLevel || 0 },
            { name: 'עמדת מחסנאי', value: graphData.warehousemanStation[0]?.warehousemanStation || 0 },
            { name: 'פינת חזרות', value: graphData.returnsCorner[0]?.returnsCorner || 0 },
          ])}
        </div>
      </div>
    );
  };

  const renderAbnormalItemsSection = () => (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-bold text-gray-800 mb-4">התפלגות סחורה תקינה חריגה</h3>
          {renderPieChart(abnormalQuantities.map(item => ({ name: item.name, value: item.abnormalGood })), "")}
        </div>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-lg font-bold text-gray-800 mb-4">התפלגות סחורה פגומה חריגה</h3>
          {renderPieChart(abnormalQuantities.map(item => ({ name: item.name, value: item.abnormalDamaged })), "")}
        </div>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-lg font-bold text-gray-800 mb-4">פריטים חריגים מפורטים</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">תאריך</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סוג</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">מוצר</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ברקוד</th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">כמות</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {abnormalItemsDetailed.map((item, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{item.branch}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.type}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.product}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.barcode}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-6" dir="rtl">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">דוחות מחסנים</h1>
      
      <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-6">
        <div className="p-4 space-y-4">
          {error && (
            <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4 rounded" role="alert">
              <div className="flex">
                <AlertCircle className="h-5 w-5 ml-2" />
                <p>{error}</p>
              </div>
            </div>
          )}

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">תאריך התחלה</label>
              <div className="relative">
                <input
                  id="startDate"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-2 pr-8 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
                <CalendarIcon className="absolute left-2 top-2 h-5 w-5 text-gray-400" />
              </div>
            </div>
            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">תאריך סיום</label>
              <div className="relative">
                <input
                  id="endDate"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-full p-2 pr-8 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                />
                <CalendarIcon className="absolute left-2 top-2 h-5 w-5 text-gray-400" />
              </div>
            </div>
            <div>
              <label htmlFor="branch" className="block text-sm font-medium text-gray-700 mb-1">סניף</label>
              <div className="relative">
                <select
                  id="branch"
                  value={selectedBranch}
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                >
                  <option value="">כל הסניפים</option>
                  {branches.map((branch) => (
                    <option key={branch} value={branch}>{branch}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
            <button
              onClick={exportToCSV}
              className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out shadow-md"
            >
              <DownloadCloud className="ml-2 h-4 w-4" />
              ייצא ל-CSV
            </button>
            <button
              onClick={fetchReports}
              className="w-full sm:w-auto inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-300 ease-in-out shadow-md"
            >
              <RefreshCw className="ml-2 h-4 w-4" />
              רענן נתונים
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <div className="flex flex-wrap justify-center gap-2 mb-4">
              <button
                onClick={() => setActiveTab('overview')}
                className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${activeTab === 'overview' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
              >
                <FileText className="inline-block mr-2 h-5 w-5" />
                סקירה כללית
              </button>
              <button
                onClick={() => setActiveTab('detailed')}
                className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${activeTab === 'detailed' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
              >
                <BarChart2 className="inline-block mr-2 h-5 w-5" />
                נתונים מפורטים
              </button>
              <button
                onClick={() => setActiveTab('abnormal')}
                className={`px-4 py-2 rounded-md transition duration-300 ease-in-out ${activeTab === 'abnormal' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
              >
                <ActivityIcon className="inline-block mr-2 h-5 w-5" />
                פריטים חריגים
              </button>
            </div>

            {activeTab === 'overview' && renderOverview()}
            {activeTab === 'detailed' && (
              <div className="space-y-4">
                {renderBarChart(graphData.storageArrangement, "storageArrangement", "#4338ca", "סידור מחסן")}
                {renderBarChart(graphData.checkLevel, "checkLevel", "#059669", "רמת בדיקה")}
                {renderBarChart(graphData.warehousemanStation, "warehousemanStation", "#d97706", "עמדת מחסנאי")}
                {renderBarChart(graphData.returnsCorner, "returnsCorner", "#e11d48", "פינת חזרות")}
                {renderBarChart(graphData.combinedScore, "combinedScore", "#7c3aed", "ציון משוקלל כולל")}
              </div>
            )}
            {activeTab === 'abnormal' && renderAbnormalItemsSection()}
          </div>

          <div className="bg-white p-4 rounded-lg shadow-lg overflow-x-auto">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">סיכום נתונים לפי סניף</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">סניף</th>
                    <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ממוצע ציון סידור מחסן</th>
                    <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ממוצע ציון רמת בדיקה</th>
                    <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ממוצע ציון עמדת מחסנאי</th>
                    <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ממוצע ציון פינת חזרות</th>
                    <th className="px-3 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">ציון משוקלל כולל</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {graphData.combinedScore.map((item) => (
                    <tr key={item.name} className="hover:bg-gray-50 transition-colors duration-150 ease-in-out">
                      <td className="px-3 py-2 whitespace-nowrap font-medium text-gray-900">{item.name}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-gray-500">{item.storageArrangement.toFixed(2)}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-gray-500">{item.checkLevel.toFixed(2)}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-gray-500">{item.warehousemanStation.toFixed(2)}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-gray-500">{item.returnsCorner.toFixed(2)}</td>
                      <td className="px-3 py-2 whitespace-nowrap text-gray-500">{item.combinedScore.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsUI;