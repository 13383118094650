import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log('index.js is running');
console.log('Redux store:', store);

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  try {
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    );
    console.log('App rendered with Redux Provider');
  } catch (error) {
    console.error('Error rendering the app:', error);
    rootElement.innerHTML = '<div style="color: red; font-size: 20px;">An error occurred while loading the application. Please check the console for more details.</div>';
  }
} else {
  console.error('Failed to find the root element');
}

reportWebVitals(console.log);