import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../../../config';

export const makeApiCall = async (endpoint, method = 'GET', data = null, isAdmin = true) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('לא נמצא טוקן. אנא התחבר מחדש.');
  }

  const config = {
    method,
    url: `${API_URL}/api/${isAdmin ? 'admin/' : ''}${endpoint}`,
    headers: { 
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  // Only add data to the config if it's not a GET or DELETE request
  if (method !== 'GET' && method !== 'DELETE' && data) {
    config.data = data;
  }

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      throw new Error('אין לך הרשאות לבצע פעולה זו.');
    }
    throw error;
  }
};

export const useAdminPanel = () => {
  const [users, setUsers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUsers = async () => {
    try {
      const data = await makeApiCall('users');
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('שגיאה בטעינת משתמשים. אנא נסה שוב.');
    }
  };

  const fetchBranches = async () => {
    try {
      const data = await makeApiCall('branches', 'GET', null, false);  // Use non-admin route
      console.log('Fetched branches:', data);
      setBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      setError('שגיאה בטעינת סניפים. אנא נסה שוב.');
    }
  };

  const checkAdminStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const userRole = localStorage.getItem('userRole');

      if (!token) {
        setError('לא נמצא טוקן. אנא התחבר מחדש.');
        setIsLoading(false);
        return;
      }

      if (userRole === 'admin') {
        setIsAdmin(true);
        await fetchUsers();
        await fetchBranches();
      } else {
        try {
          const response = await makeApiCall('users/me');
          const userIsAdmin = response.role === 'admin';
          setIsAdmin(userIsAdmin);
          if (userIsAdmin) {
            await fetchUsers();
            await fetchBranches();
          }
        } catch (error) {
          if (error.message === 'אין לך הרשאות לבצע פעולה זו.') {
            setIsAdmin(false);
          } else {
            throw error;
          }
        }
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      setError('שגיאה בבדיקת סטטוס מנהל. אנא נסה שוב.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeleteUser = async (userId) => {
    try {
      console.log('Attempting to delete user:', userId);
      const response = await makeApiCall(`users/${userId}`, 'DELETE', null, true);
      console.log('Delete user response:', response);
      
      if (response && response.message === 'User deleted successfully') {
        setUsers(prevUsers => prevUsers.filter(user => user._id !== userId));
        setSuccess('המשתמש נמחק בהצלחה');
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(error.response.data.message || 'שגיאה במחיקת משתמש. אנא נסה שוב.');
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('לא התקבלה תשובה מהשרת. נא לנסות שוב מאוחר יותר.');
      } else {
        console.error('Error message:', error.message);
        setError('אירעה שגיאה במחיקת המשתמש. נא לנסות שוב.');
      }
    }
  };

  const handleUpdateUser = async (updatedUser) => {
    try {
      console.log('Updating user:', updatedUser);
      const response = await makeApiCall(`users/${updatedUser._id}`, 'PUT', updatedUser);
      console.log('Update response:', response);

      if (response && response.user) {
        setUsers(prevUsers => prevUsers.map(user => 
          user._id === updatedUser._id ? { ...user, ...response.user } : user
        ));
        setSuccess('המשתמש עודכן בהצלחה');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error updating user:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        setError(error.response.data.message || 'שגיאה בעדכון המשתמש. אנא נסה שוב.');
      } else if (error.request) {
        console.error('No response received:', error.request);
        setError('לא התקבלה תשובה מהשרת. נא לנסות שוב מאוחר יותר.');
      } else {
        console.error('Error message:', error.message);
        setError('אירעה שגיאה. נא לנסות שוב.');
      }
    }
  };

  const refreshUserList = () => {
    fetchUsers();
  };

  const refreshBranchList = () => {
    fetchBranches();
  };

  useEffect(() => {
    checkAdminStatus();
  }, [checkAdminStatus]);

  return {
    isLoading,
    isAdmin,
    error,
    success,
    users,
    branches,
    handleDeleteUser,
    handleUpdateUser,
    setError,
    setSuccess,
    refreshUserList,
    refreshBranchList
  };
};