import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../store/slices/authSlice';
import { API_URL } from '../config';
import { User, Lock, Warehouse, ArrowRight, Home, AlertCircle } from 'lucide-react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_URL}/api/users/login`, { username, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });

      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('userRole', response.data.role);
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('loginTimestamp', new Date().getTime().toString());
        
        dispatch(setCredentials({ user: response.data, token: response.data.token }));
        navigate('/dashboard');
      } else {
        throw new Error('לא התקבל טוקן מהשרת');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'שם משתמש או סיסמה לא נכונים');
      } else {
        setError('אירעה שגיאה. נא לנסות שוב.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-200 flex items-center justify-center p-4 sm:p-8" dir="rtl">
      <div className="container mx-auto max-w-md">
        <h1 className="text-3xl font-bold text-gray-800 mb-8 text-center">התחברות למערכת</h1>
        
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex flex-col items-center mb-6">
            <Warehouse className="h-16 w-16 text-gray-800 mb-4" />
            <p className="text-gray-600">אנא הכנס את פרטי ההתחברות שלך</p>
          </div>
          
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700 mb-1">שם משתמש</label>
              <div className="relative">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  placeholder="הזן שם משתמש"
                  value={username}
                  onChange={handleInputChange}
                />
                <User className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              </div>
            </div>
            
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">סיסמה</label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                  placeholder="הזן סיסמה"
                  value={password}
                  onChange={handleInputChange}
                />
                <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              </div>
            </div>

            {error && (
              <div className="bg-red-100 border-r-4 border-red-500 text-red-700 p-4 rounded" role="alert">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 ml-2" />
                  <p>{error}</p>
                </div>
              </div>
            )}

            <div>
              <button
                type="submit"
                className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out shadow-lg w-full flex items-center justify-center"
                disabled={isLoading}
              >
                {isLoading ? 'מתחבר...' : 'התחבר למערכת'}
                {!isLoading && <ArrowRight className="ml-2 h-5 w-5" />}
              </button>
            </div>
          </form>
        </div>
        
        <div className="mt-4 text-center">
          <button
            onClick={() => navigate('/')}
            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out"
          >
            <Home className="h-5 w-5 ml-2" />
            חזרה לדף הבית
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;