import React from 'react';
import UserManagement from './UserManagement';
import BranchManagement from './BranchManagement';
import UserList from './UserList';
import { useAdminPanel } from './hooks';
import { LoadingSpinner, AccessDenied, PanelHeader, ErrorMessage, SuccessMessage } from './ui';

const AdminPanel = () => {
  const { 
    isLoading, 
    isAdmin, 
    error, 
    success, 
    users, 
    branches,
    handleDeleteUser,
    handleUpdateUser
  } = useAdminPanel();

  if (isLoading) return <LoadingSpinner />;
  if (!isAdmin) return <AccessDenied />;

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <PanelHeader />
        <ErrorMessage error={error} />
        <SuccessMessage success={success} />
        
        <div className="p-6 space-y-6">
          <UserManagement branches={branches} />
          <BranchManagement />
          <UserList 
            users={users} 
            branches={branches} 
            onDeleteUser={handleDeleteUser} 
            onUpdateUser={handleUpdateUser}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;